// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import {
    BREADCRUMBS,
    CATEGORY_NAMES,
    DOWNLOAD_DEFAULT_FILENAME,
    BILLING_ID,
    ID_TYPES,
    DOCUMENT_PAGE_TITLE,
} from '@constants';
import utils from '@utils/utilities';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import { bindActionCreators } from 'redux';
import articlesManagerActions from '../redux/ArticlesManager.actions';
import reduxStore from '@reduxStore';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import ErrorMessage from '@reusable/ErrorMessage/ErrorMessage';
import { hashHistory } from 'react-router';
import LoadingSpinner from '@reusable/LoadingSpinner/LoadingSpinner.index';
import ElasticSingleDocumentView from './ElasticSingleDocumentView';
import categoryUtils, { getCategoryOrder, withCustomNewsTranslation } from '@utils/categoryUtils';
import { withContentSourceFilter } from '@utils/utilities';
import costCodeUtils from '@utils/costCodeUtils';
import articleNavigationActions from '../redux/ArticleNavigation.actions';
import deliveryUtilities from '@utils/deliveryUtilities';

import type { ContentTypesType, SearchParamsType, SearchResultsType, SearchStateType } from './typeGuards/ResultsList.typeGuards';
import type { OriginalArticleInfo } from '../redux/flow/MainSearch.type.guards';
import type { SelectedArticleType } from './typeGuards/ElasticSingleDocument.typeGuards';
import type { ArticleNavigationType } from '@reusable/ArticleNavigation/typeGuards/ArticleNavigation.typeGuards';
import type { RouterLocation } from '@utils/flow/utilities.type.guards';
import type { BreadCrumbsType } from '@reusable/Breadcrumbs/typeGuards/Breadcrumbs.typeGuards';
import mainActions from 'scripts/pages/Main/Main.actions';

type State = {
    hasError: boolean,
    isLoading: boolean,
    internalLink: boolean,
    negativeTopics: Array<string>,
    originalArticleInfo: OriginalArticleInfo,
};

type Props = {
    selectedArticle: SelectedArticleType,
    searchParams: SearchParamsType,
    searchState: SearchStateType,
    isSnapshotVisible: boolean,
    articleNavigation: ArticleNavigationType,
    billingId: string,
    location: RouterLocation,
    initViewArticle: (requestData: Object) => void,
    useNewResearchSummary: boolean,
    searchResults: SearchResultsType,
    popupModel: PopupModel,
    isBrazilianOwnershipEnabled: boolean,
    language: string,
    searchDocAccessEvent: () => void,
    emailAddress: string,
    contentTypes: Array<ContentTypesType>,
    updateBreadcrumbs: (breadcrumbs: BreadCrumbsType) => void,
    previousRoute: string,
};

class ElasticSingleDocument extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hasError: false,
            isLoading: true,
            internalLink: false,
            negativeTopics: [],
            originalArticleInfo: {},
        };
    }

    setBreadcrumbs() {
        let articleType = '';
        const { selectedArticle } = this.props;

        if (selectedArticle.article) {
            articleType = utils.getUrlQueryParam('category') || selectedArticle.article.articleType;
        }

        let breadcrumbs = new BreadCrumbsModel.Builder(BREADCRUMBS.DOCUMENT)
            .setProperty('category', this.props.searchParams.category)
            .setProperty('articleType', articleType)
            .setProperty('searchQuery', utils.sanitizeSearchStringInput(this.props.searchParams.query))
            .setProperty('searchQueryType', this.props.searchParams.searchType)
            .setProperty('isSnapshotVisible', this.props.isSnapshotVisible)
            .setProperty('internalLink', this.state.internalLink)
            .setProperty('articleIndex', this.state.originalArticleInfo.index)
            .setProperty('prevPath', this.props.previousRoute)
            .setProperty('launchedFrom', this.props.searchState.launchedFrom)
            .build().breadcrumbs;
        this.props.updateBreadcrumbs(breadcrumbs);
    }

    getRequestPropsFromQuery = () => {
        let {
            id,
            query,
            type,
            category,
            billingId,
            prefilterQuery,
            proximity,
            costCode,
            docId,
            singleSource,
            fuzzyThreshold,
        } = this.props.location.query;
        let idType = ID_TYPES.ID;
        let index = this.props.articleNavigation.index;
        const hasSelectedArticle = !!this.props.selectedArticle.article;
        const selectedArticle = this.props.selectedArticle.article;
        const includeTerms =
            hasSelectedArticle && this.props.selectedArticle.article.includeTerms
                ? this.props.selectedArticle.article.includeTerms
                : [];
        const terms =
            hasSelectedArticle && this.props.selectedArticle.article.terms
                ? this.props.selectedArticle.article.terms
                : [];

        if (hasSelectedArticle) {
            id = this.props.selectedArticle.article.id;
        }

        if (docId) {
            idType = ID_TYPES.DOC_ID;
            id = docId;
        }

        //get investigationId from props
        if (!billingId) {
            billingId = this.props.billingId;
        }
        if (!billingId) {
            billingId = localStorage.getItem(BILLING_ID);
        }
        billingId = billingId || costCodeUtils.generateBillingId();

        //change category if the original one is different than the one for the internal link document
        let updatedCategory = this.getCategoryBasedOnDocumentCategory(selectedArticle, category);

        return {
            [idType]: id,
            query,
            type,
            category: updatedCategory,
            index,
            billingId,
            prefilterQuery,
            proximity,
            includeTerms,
            costCode,
            singleSource,
            fuzzyThreshold,
            terms,
        };
    };

    shouldComponentUpdate(nextProps: $ReadOnly<Props>, nextState: $ReadOnly<State>): boolean {
        // update the originalArticleInfo when navigating articles with next/previous
        if (
            this.state.originalArticleInfo &&
            !!nextState.originalArticleInfo &&
            !!this.props.selectedArticle.article &&
            !this.props.location.query.docId &&
            nextState.originalArticleInfo.id !== this.props.selectedArticle.article.id
        ) {
            this.updateOriginalArticleInfo();
        }

        return true;
    }

    UNSAFE_componentWillMount() {
        const requestData = this.getRequestPropsFromQuery();
        this.updateOriginalArticleInfo(requestData);
        this.props.initViewArticle(requestData);
    }

    componentDidUpdate(prevProps) {
        if (!this.state.hasError && this.props.selectedArticle?.article?.id) {
            this.setBreadcrumbs();

            if (!!this.props.selectedArticle.article) {
                utils.visitedArticlesController.setVisitedArticle(this.props.selectedArticle.article.id);
            }

            const { selectedArticle: prevSelectedArticle } = prevProps || {};
            const { selectedArticle } = this.props;
            if (prevSelectedArticle?.article?.id !== selectedArticle.article.id && !this.state.internalLink) {
                this.updateSearchParamId();
            }
        }
    }

    componentDidMount() {
        document.title = DOCUMENT_PAGE_TITLE;

        let selectedArticle = this.props.selectedArticle;

        if (!selectedArticle && !selectedArticle.article && !selectedArticle.article.articleType) {
            this.handleError();
        }
        window.scrollTo(0, 0);
    }
    componentWillUnmount() {
        reduxStore.dispatch(mainActions.resetCurrentEntity());
    }

    updateSearchParamId() {
        if (!this.props.selectedArticle || this.state.internalLink) return;
        const { location, router, selectedArticle } = this.props;

        const { article } = selectedArticle;

        const searchParams = new URLSearchParams(location.search);
        searchParams.set('id', article.id);

        const encodedSearchParams = Array.from(searchParams.entries())
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&');

        router.replace({
            pathname: location.pathname,
            search: `?${encodedSearchParams}`,
        });
    }

    updateOriginalArticleInfo = (requestData = null) => {
        if (!requestData) {
            requestData = this.getRequestPropsFromQuery();
        }

        this.setState({
            originalArticleInfo: requestData,
        });
    };

    handleError = () => {
        this.setState({
            hasError: true,
            isLoading: false,
        });
    };

    handleErrorMessageRedirect = () => {
        hashHistory.push('/start');
    };

    closePopupModal = () => {
        this.setState({
            popupModalVisible: false,
        });
    };

    showPopupModal = () => {
        this.setState({
            popupModalVisible: true,
        });
    };

    handleInternalLink = (e, link) => {
        e.preventDefault();

        let idType = ID_TYPES.DOC_ID;
        const href = link.getAttribute('href');
        let id = utils.getURLParameter(href, 'docId');
        const category = utils.getURLParameter(href, 'category');
        const type = utils.getURLParameter(href, 'type');
        const singleSource = utils.getURLParameter(href, 'singleSource');
        const costCode = utils.getURLParameter(href, 'costCode');
        const prefilterQuery = this.state.originalArticleInfo.prefilterQuery;
        const billingId = this.state.originalArticleInfo.billingId;
        const query = this.state.originalArticleInfo.query;
        const { location, router, selectedArticle } = this.props;
        const { pathname } = location;

        if (!id) {
            idType = ID_TYPES.ID;
            id = utils.getURLParameter(href, 'id');
        }
        this.setState({
            internalLink: true,
        });

        //change category if the original one is different than the one for the internal link document
        let updatedCategory = this.getCategoryBasedOnDocumentCategory(selectedArticle, location.category);

        if (href) {
            let search = href.replace(pathname, '');
            search = search.replace('/#', '');
            search = search.replace(category, updatedCategory);
            router.replace({ pathname, search });
        }

        this.props.initViewArticle({
            [idType]: id,
            query,
            type,
            category: updatedCategory,
            prefilterQuery,
            billingId,
            singleSource,
            costCode,
        });
    };

    handleDownloadTable = (link) => {
        const tableId = link.getAttribute('data-tableid');
        const table = document.querySelectorAll('[xml\\:id="' + tableId + '"]');

        if (table && table[0]) {
            deliveryUtilities.exportToExcel(table[0]);
        }
    };

    backToOriginalDoc = () => {
        this.setState({
            internalLink: false,
        });

        this.props.initViewArticle({
            id: this.state.originalArticleInfo.id,
            query: this.state.originalArticleInfo.query,
            type: this.state.originalArticleInfo.type,
            category: this.state.originalArticleInfo.category,
            prefilterQuery: this.state.originalArticleInfo.prefilterQuery,
            billingId: this.state.originalArticleInfo.billingId,
            proximity: this.state.originalArticleInfo.proximity,
            includeTerms: this.state.originalArticleInfo.includeTerms,
            costCode: this.state.originalArticleInfo.costCode,
            singleSource: this.state.originalArticleInfo.singleSource,
            terms: this.state.originalArticleInfo.terms,
        });
    };

    showPopup = (event: SyntheticEvent<HTMLButtonElement>) => {
        const popupType = event.currentTarget.value;
        const { selectedArticle } = this.props;
        const { query, searchType } = this.props.searchParams;
        const { article } = this.props.selectedArticle;
        const researchSummary = !this.props.useNewResearchSummary
            ? this.props.articleNavigation.params.researchSummary
            : undefined;
        const categoryOrder = this.props.useNewResearchSummary ? getCategoryOrder(this.props.contentTypes) : undefined;
        const location = this.props.location.query;
        const { emailAddress } = this.props;
        const articleType = location.category ?? article.articleType;
        const currentDate = new Date();
        const isCustomNewsSource = categoryUtils.isCustomNewsSource(articleType);
        const categoryName = isCustomNewsSource
            ? withCustomNewsTranslation(articleType, searchType)
            : categoryUtils.getCategoryName(articleType);
        const searchQuery = this.props.articleNavigation.params.query || location.query;
        const singleSource = this.props.articleNavigation.params.singleSource || location.singleSource;
        const fileNameExtension = searchQuery + '_';
        const fileName =
            DOWNLOAD_DEFAULT_FILENAME +
            utils.formatReportFileName(fileNameExtension) +
            utils.formatFileName(categoryName, isCustomNewsSource) +
            '_' +
            utils.formatReportDateWithtTimezoneOffset(currentDate.getTime());


        let updatedCategory = this.getCategoryBasedOnDocumentCategory(selectedArticle, location.category);
        
        let popupModel = new PopupModel.Builder(popupType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('fileName', fileName)
            .setPopupProperty('emailAddress', emailAddress)
            .setPopupProperty('articles', [article])
            .setPopupProperty('searchQuery', searchQuery)
            .setPopupProperty('searchQueryType', searchType)
            .setPopupProperty('singleSource', singleSource)
            .setPopupProperty('articleType', updatedCategory)
            .setPopupProperty('originalArticleType', articleType);

        if (query === location.query) {
            const categoryConfig = withContentSourceFilter().extendConfig(articleType);
            const contentSource = this.props.searchResults[articleType].contentSource;
            const postFilters = this.props.searchResults[articleType].postFilters;
            const totalArticlesCount = this.props.searchResults[articleType].count;
            popupModel
                .setPopupProperty('researchSummary', researchSummary)
                .setPopupProperty('categoryOrder', categoryOrder)
                .setPopupProperty('totalArticlesCount', totalArticlesCount)
                .setPopupProperty('postFilters', postFilters)
                .setPopupProperty('articleType', categoryConfig ? categoryConfig.category : updatedCategory);

            if (
                categoryConfig &&
                (categoryConfig.category === CATEGORY_NAMES.NEGATIVE_NEWS ||
                    categoryConfig.category === CATEGORY_NAMES.CUSTOM_NEWS)
            ) {
                popupModel.setPopupProperty('contentLanguage', categoryConfig.contentLanguage);
                popupModel.setPopupProperty(
                    'contentSource',
                    categoryConfig.contentSource ? categoryConfig.contentSource : contentSource
                );
                popupModel.setPopupProperty('customNews', categoryConfig.customNews ? categoryConfig.customNews : null);
            }
        }

        popupModel.build();
        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    };

    getCategoryBasedOnDocumentCategory = (selectedDocument, currentCategory) => {
            const { article } = selectedDocument || {}; 

            if(!article) return currentCategory;

            // this checks if the category received on the document is different than the original selected category on the Results List
            const isCategoryMismatched = article.category !== currentCategory || article.articleType !== currentCategory;

            // if categories don't match, return the category or articleType of the loaded document (they should be the same, but sometimes the category is missing from the object)
            // otherwise return the currently selected category from the Results List
            return isCategoryMismatched ? article.category || article.articleType : currentCategory;
    }

    render(): React.Node {
        let { searchParams, location } = this.props;
        const { singleSource, fuzzyThreshold } = location.query;
        let article = this.props.selectedArticle.article
            ? { ...this.props.selectedArticle.article, singleSource, fuzzyThreshold }
            : {};
        let category = article.articleType || location.query.category;
        // error loading article, but not in a article navigation context
        const hasLoadingError = this.props.articleNavigation.hasError && this.props.articleNavigation.index === null;
        return (
            <React.Fragment>
                {hasLoadingError && (
                    <ErrorMessage
                        header="SingleDocumentView.ErrorMessage.documentNotFound.header"
                        body="SingleDocumentView.ErrorMessage.documentNotFound.body"
                        action={this.handleErrorMessageRedirect}
                    />
                )}
                {!hasLoadingError && (
                    <ElasticSingleDocumentView
                        popupModel={this.props.popupModel}
                        closePopupModal={this.closePopupModal}
                        article={article}
                        showPopup={this.showPopup}
                        query={searchParams.query === location.query.query}
                        location={location}
                        hasError={this.props.articleNavigation.hasError}
                        negativeTopics={this.state.negativeTopics}
                        internalLink={this.state.internalLink}
                        originalArticleInfo={this.state.originalArticleInfo}
                        handleInternalLink={this.handleInternalLink}
                        onDownloadTable={this.handleDownloadTable}
                        backToOriginalDoc={this.backToOriginalDoc}
                        category={category}
                        isBrazilianOwnershipEnabled={this.props.isBrazilianOwnershipEnabled}
                        searchDocAccessEvent={this.props.searchDocAccessEvent}
                        language={this.props.language}
                    />
                )}
                {this.props.selectedArticle && utils.isEmptyObject(this.props.selectedArticle) && !hasLoadingError && (
                    <LoadingSpinner fixedSpinner={true} isVisible={true} />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        searchResults: state.searchResults,
        articleNavigation: state.articleNavigation,
        selectedArticle: state.articlesManager.selectedArticle,
        isLoading: state.articlesManager.selectedArticle && state.articlesManager.selectedArticle.length === 0,
        searchParams: state.searchParams,
        previousRoute: state.breadcrumbs.prevPath,
        prevQuery: state.breadcrumbs.query,
        selectedReport: state.reportBuilder.selectedReport,
        popupModel: state.popupModel,
        isSnapshotVisible: state.user.preferences.generalSettings.showSnapshot,
        billingId: state.investigation.billingId,
        emailAddress: state.user.email,
        contentTypes: state.user.preferences.generalSettings.contentTypes,
        useNewResearchSummary: state.user.useNewResearchSummary,
        isBrazilianOwnershipEnabled: state.user.isBrazilianOwnershipEnabled,
        language: state.user.preferences.language,
        searchState: state.searchState,
    };
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
            setSelectedArticle: articlesManagerActions.selectArticle,
            initViewArticle: articleNavigationActions.initViewArticle,
            searchDocAccessEvent: articleNavigationActions.searchDocAccessEvent,
        },
        dispatch
    );
};

export default (connect(mapStateToProps, mapDispatchToProps)(ElasticSingleDocument): React.AbstractComponent <Props, State>);
