import React, { Fragment } from 'react';
import withAutosave from '../HOC/WithAutosave';
import { FE_LOCK_NAMES } from '@constants';
import { FormattedMessage } from 'react-intl';
import AdminLock from '@reusable/AdminLock/AdminLock';
import InformationIcon from '@reusable/InformationIcon/InformationIcon';




const CompanySourceSearches = (props) => {
    const handleExclusionOfLowQualityDocuments = (event) => {
        props.handleAutosave(props.handleExclusionOfLowQualityDocuments, [event]);
    };

    const excludeLowQualityInfoModalContent = () => {
        const infoItems = [
            'UserPreferences.ExcludeLowQualityDocuments.Info.Option',
        ];

        const content = infoItems.map((item, index) => (
            <div key={item} className="information-item">
                <FormattedMessage id={item} key={index} />
            </div>
        ));

        const title = [<FormattedMessage key={0} id="UserPreferences.ExcludeLowQualityDocuments.Info.Title" />];
        const infoModalClass = 'user-preferences-exclude-low-quality-documents';

        return { content, title, infoModalClass };
    };

        const excludeBusinessInfo: {
            content?: Array<React$Element<'div'>>,
            title?: Array<React$Element<'div'>>,
            infoModalClass?: string,
        }= excludeLowQualityInfoModalContent();
    return (
        <Fragment>
            <div className="container-section">
                <div className="user-preferences-category-container__row">
                    <h3 className="user-preferences-category-container__topic user-preferences-search-results">
                        <FormattedMessage id="UserPreferences_topic_company_source_searches" />
                    </h3>
                    {props.autoSaveMessage}
                    {(props.userIsAdmin || props.searchResultsLock) && (
                        <AdminLock
                            lockName={FE_LOCK_NAMES.companySource}
                            onClickLock={props.onClickLock}
                            isSectionLock={true}
                            isLocked={props.searchResultsLock}
                            isDisabled={!props.userIsAdmin && props.searchResultsLock}
                        />
                    )}
                </div>           

                <div className="toggle-exclude-low-quality-documents">
                    <div className="custom-toggle">
                        <div
                            className={
                                !props.userIsAdmin && props.searchResultsLock
                                    ? 'custom-toggle disabled'
                                    : 'custom-toggle'
                            }
                        >
                            <input
                                id={'user-preferences-exclude-low-quality-documents'}
                                className="custom-toggle-checkbox"
                                type="checkbox"
                                title={'Exclude Company Sources that are greater than 100,000 words'}
                                checked={props.excludeLowQualityDocuments === true ? 'checked' : ''}
                                onChange={handleExclusionOfLowQualityDocuments}
                                data-testid={`user-preferences-exclude-low-quality-documents-${props.excludeLowQualityDocuments ? 'enabled' : 'disabled'}`}
                            />
                            <label htmlFor={'user-preferences-exclude-low-quality-documents'} 
                                className="custom-toggle-label"
                            ></label>
                        </div>
                    </div>
                    <span>
                        <FormattedMessage id={'UserPreferences_exclude_low_quality_documents'} />
                    </span>
                    {props.withInformationIcon && <InformationIcon {...excludeBusinessInfo} />}
                </div>
            </div>
        </Fragment>
    );
};

export default withAutosave()(CompanySourceSearches);
