import React from "react";
import { Loader } from 'semantic-ui-react';

const SortComponent = ({ sortInfo, columnKey, sortStatus } ) => {
    const sortBy = sortInfo ? sortInfo.sortBy :'';
    const sortDirection = sortInfo ? sortInfo.direction :'';
    const isLoading = sortStatus?.column === columnKey && sortStatus.isSorting;

    const renderSortIcon = () => {
        return (
            <span className="sort-button-container">
            <span className="sort-button-top">
              <svg className="sort-button-icon-container" viewBox="-2 -2 10.67 10.67">
                  <path
                      className={`sort-button-icon ${(sortDirection === 'asc' && columnKey === sortBy) ? 'filled' : ''}`}
                      d="M3.33 0 L6.67 5.77 L0 5.77 Z"/>
              </svg>
            </span>
            <span className="sort-button-bottom">
              <svg className="sort-button-icon-container" viewBox="-2 -2 10.67 10.67">
                  <path
                      className={`sort-button-icon ${(sortDirection === 'desc' && columnKey === sortBy) ? 'filled' : ''}`}
                      d="M3.33 6.67 L6.67 0.89 L0 0.89 Z"/>
              </svg>
            </span>
        </span>
        )
    }

    const renderLoadingIcon = () => {
        return (
            <div className="load-icon-container">
                <Loader className="load-icon" size="small" active />
            </div>
        )
    }

    return (
        <>
            {!isLoading ? renderSortIcon() : renderLoadingIcon()}
        </>

    )
}

export default SortComponent;